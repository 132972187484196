import React from 'react'
import { GetServerSideProps } from 'next'
import { getServerSession } from 'next-auth'
import { getNextAuthOptions } from 'src/nextAuthOptions'
import { getIsLoggedIn, getRoute } from 'utils/helpers'
import { AuthScreen } from 'components/Authentication'
import { PageWrapper } from 'components/PageWrapper'
// https://github.com/chrishornmem/next-auth-example/blob/master/pages/auth/customSignin.jsx

const Login = () => (
  <PageWrapper title="Login">
    <AuthScreen isLogin={true} />
  </PageWrapper>
)

export const getServerSideProps: GetServerSideProps = async (context) => {
  try {
    const session = await getServerSession(
      context.req,
      context.res,
      getNextAuthOptions(context.req)
    )
    return getIsLoggedIn(session)
      ? {
          redirect: { destination: getRoute('home'), permanent: true },
        }
      : { props: {} }
  } catch (error) {
    return {
      redirect: {
        destination: getRoute('home'),
        permanent: true,
      },
    }
  }
}

export default Login
